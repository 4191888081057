document.addEventListener('DOMContentLoaded', () => {
	let count = 0;
    const maxCount = 530;
    const message = document.getElementById('service_form_message');
    const counter = document.getElementById('letter-count');

    if (!message || !counter) {
        return;
    }

    counter.textContent = count + '/' + maxCount;

    message.addEventListener('input', (e) => {
        count = message.value.length;

        if (count > maxCount) {
            message.value = message.value.substr(0, maxCount);
            count = message.value.length;
        }

        counter.textContent = count + '/' + maxCount;
    });
});
